import { useEffect } from "react";


export const MicrophonePermission = () => {
  
    useEffect(() => {
      checkMicrophoneAccess();
    }, []);
  
    const checkMicrophoneAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });     
        stream.getTracks().forEach(track => track.stop()); // قطع استریم بعد از درخواست موفقیت‌آمیز
      } catch (err) {
      }
    };

  
    return null
  };