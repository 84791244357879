import { SvgPropsType } from "../../types/layout-types";

export const SvgSend = ({ className, onClick }: SvgPropsType) => {
    return (
        <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.40995 21.7492C4.28995 21.7492 3.57995 21.3692 3.12995 20.9192C2.24995 20.0392 1.62995 18.1692 3.60995 14.1992L4.47995 12.4692C4.58995 12.2392 4.58995 11.7592 4.47995 11.5292L3.60995 9.79917C1.61995 5.82917 2.24995 3.94917 3.12995 3.07917C3.99995 2.19917 5.87995 1.56917 9.83995 3.55917L18.3999 7.83917C20.5299 8.89917 21.6999 10.3792 21.6999 11.9992C21.6999 13.6192 20.5299 15.0992 18.4099 16.1592L9.84995 20.4392C7.90995 21.4092 6.46995 21.7492 5.40995 21.7492ZM5.40995 3.74917C4.86995 3.74917 4.44995 3.87917 4.18995 4.13917C3.45995 4.85917 3.74995 6.72917 4.94995 9.11917L5.81995 10.8592C6.13995 11.5092 6.13995 12.4892 5.81995 13.1392L4.94995 14.8692C3.74995 17.2692 3.45995 19.1292 4.18995 19.8492C4.90995 20.5792 6.77995 20.2892 9.17995 19.0892L17.7399 14.8092C19.3099 14.0292 20.1999 12.9992 20.1999 11.9892C20.1999 10.9792 19.2999 9.94917 17.7299 9.16917L9.16995 4.89917C7.64995 4.13917 6.33995 3.74917 5.40995 3.74917Z"  />
            <path d="M10.8414 12.75H5.44141C5.03141 12.75 4.69141 12.41 4.69141 12C4.69141 11.59 5.03141 11.25 5.44141 11.25H10.8414C11.2514 11.25 11.5914 11.59 11.5914 12C11.5914 12.41 11.2514 12.75 10.8414 12.75Z"  />
        </svg>

    );
};