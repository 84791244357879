import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { VoiceChat } from './pages/VoiceChat';

const queryClient = new QueryClient()


function App() {
  return (
    <div>
      <  QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<VoiceChat />} />
          </Routes>
        </BrowserRouter>
      </ QueryClientProvider>
    </div>
  );
}

export default App;





