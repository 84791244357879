import { base_url, baseApi } from "./baseApi";

export const SendVoiceApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}stt/audio-to-text/`, formData:true ,  method: "POST" , body:postData  })
    return { data };
}


export const TextToVoiceApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}tts/convert/` ,  method: "POST" , body:JSON.stringify(postData)  })
    return { data };
}