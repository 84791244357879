import { useEffect } from "react";
import { methodHandelScrollBoxToBottom } from "../../../lib/layout-lib";


export const SocketConnection = ({ setSocket, setChat, socket }: any) => {

    const createWebSocketConnection = () => {
        const wsUrl = 'wss://api.pixelyai.com/ws/chat/99044815-10f1-415b-ba56-7deac00db61f/client/user1863863@gmail.com/'
        const newSocket = new WebSocket(wsUrl);

        newSocket.onopen = () => {
            setSocket(newSocket);
            console.log('Connected to server');
        };

        newSocket.onclose = () => {
            console.log('WebSocket disconnected, retrying...');
            setTimeout(() => {
                createWebSocketConnection()
            }, 3000); // تلاش مجدد اتصال پس از 3 ثانیه
        };

        newSocket.onmessage = (event) => {
            try {
                let newMessage = JSON.parse(event.data)
                if (newMessage?.session != 'client') {
                    setChat((prevChat: any) => [
                        ...prevChat,
                        {
                            'type': newMessage?.session,
                            'message': newMessage?.message,
                            'voice': null,
                            'date': new Date().toISOString(),
                        }
                    ]);
                }
                methodHandelScrollBoxToBottom()
            } catch (e) {
                return;
            }
        };

        newSocket.onerror = (error) => {
            newSocket.close();
        };
    }


    const closeWebSocketConnection = () => {
        const closeSocket = () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
                setSocket(null)
            }
        };
        window.addEventListener('beforeunload', closeSocket);
        return () => {
            window.removeEventListener('beforeunload', closeSocket);
            closeSocket();
        };
    }


    useEffect(() => {
        createWebSocketConnection()
    }, [])

    useEffect(() => {
        closeWebSocketConnection()
    }, [socket]);


    return null
}