import { useEffect, useRef, useState } from "react"
import { ButtonSection } from "./_component/ButtonSection"
import { SocketConnection } from "./_component/SocketConnection";
import { useTextToVoice } from "../../hooks/voice-hook";
import { ChatContent } from "./_component/ChatContent";
import { MicrophonePermission } from "./_component/MicrophonePermission";


export const VoiceChat = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [text, setText] = useState('')
    const [chat, setChat] = useState<any>([])
    const [voiceClient, setVoiceClient] = useState(null)
    const [socket, setSocket] = useState<any>(null);
    const { mutate } = useTextToVoice(setChat, chat)

    useEffect(() => {
        if (chat.length > 0 && chat[chat.length - 1].type == 'bot_response' && chat[chat.length - 1].voice == null) {
            let postData = {
                'text': chat[chat.length - 1].message
            }
            mutate(postData)
        }
    }, [chat])

    return (
        <>
            <div className="w-full md:h-svh flex items-center md:bg-[#999999] bg-black  ">
                <div className=" w-full max-w-md md:h-[90vh] h-svh border-[6px] border-black flex items-center justify-center bg-black mx-auto rounded-3xl overflow-hidden p-[6px] relative">
                    <div className={` absolute w-full h-full ${isRecording && 'bg-border'} `}></div>
                    <div className="w-full h-full flex flex-col justify-between bg-black shadow-[-2px_6px_17px_0px_rgba(0,0,0,1)] rounded-2xl overflow-hidden z-10 pt-6">
                        {chat.length == 0 &&
                            <div className="w-full h-[calc(100%-120px)] flex items-center">
                                <textarea className="w-full h-full flex items-center justify-center p-5 bg-transparent text-white text-2xl resize-none outline-none no-scrollbar" onChange={(e) => { setText(e.target.value) }} value={text} />
                            </div>
                        }
                        {chat.length > 0 &&
                            <ChatContent chat={chat} />
                        }
                        <ButtonSection
                            setIsRecording={setIsRecording}
                            isRecording={isRecording}
                            setVoiceClient={setVoiceClient}
                            voiceClient={voiceClient}
                            setText={setText}
                            text={text}
                            socket={socket}
                            setChat={setChat}
                            chat={chat}
                        />
                    </div>
                    <MicrophonePermission />
                </div>
            </div>
            <SocketConnection
                socket={socket}
                setSocket={setSocket}
                setChat={setChat}
            />
         
        </>
    )
}

