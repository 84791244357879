import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { SendVoiceApi, TextToVoiceApi } from "../api/voice-api"
import { base_url_pure } from "../api/baseApi"
import { methodHandelScrollBoxToBottom } from "../lib/layout-lib"

export const useSendVoice = (
  setText: (str: string) => void
) => {
  return useMutation({
    mutationFn: (postData: any) => SendVoiceApi(postData),
    onSuccess: (res: any) => {
      if (res.data.text) {
        setText(res.data.text)
      }
    },
    onError: (err: any) => {
      console.log(err);
    }
  })
}


export const useTextToVoice = (
  setChat: (obj: any) => void,
  chat: any
) => {
  return useMutation({
    mutationFn: (postData: any) => TextToVoiceApi(postData),
    onSuccess: (res: any) => {
      let data = [...chat]
      if (data.length > 0 && data[data.length - 1].type == 'bot_response' && chat[chat.length - 1].voice == null) {
        const mp3Url = `${base_url_pure}${res?.data?.mp3_url}`
        data[data.length - 1].voice = mp3Url;
        setChat(data)
        methodHandelScrollBoxToBottom()
      }
    },
    onError: (err: any) => {
      console.log(err);
    }
  })
}



async function urlToBlob(url: string) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error fetching and converting to Blob:", error);
  }
}