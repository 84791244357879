import { SvgPropsType } from "../../types/layout-types";

export const SvgMicrophone = ({ className, onClick }: SvgPropsType) => {
    return (
        <svg className={className} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5013 18.7285C17.1717 18.7285 19.3346 16.5656 19.3346 13.8952V7.24935C19.3346 4.57893 17.1717 2.41602 14.5013 2.41602C11.8309 2.41602 9.66797 4.57893 9.66797 7.24935V13.8952C9.66797 16.5656 11.8309 18.7285 14.5013 18.7285Z"  stroke-width="2.7981" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.25391 11.6582V13.7124C5.25391 18.8115 9.39849 22.9561 14.4977 22.9561C19.5968 22.9561 23.7414 18.8115 23.7414 13.7124V11.6582"  stroke-width="2.7981" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.5 22.957V26.582" stroke-width="2.7981" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};